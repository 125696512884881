import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { transformPostSchema } from '../util';
import HeaderMeta from '../components/HeaderMeta';

import {
  BlogPostBox,
  HeadingBox,
  BlogPostList,
  OctopusBlueFrame,
  SectionGroup,
} from "@minware/ui-minware";


export const BlogHomeTemplate = ({
  content,
  postList,
  octopus,
}) => {
  const featuredPostNew = transformPostSchema(content.featuredPost, true);
  octopus = octopus || {};
  postList = postList || [];
  const posts = postList.map(({node}) => transformPostSchema(node));

  const headerWithCanon = {
    ...content?.header,
    canonicalUrl: '/blog',
  };

  return (
    <Layout desktopBgImageName="none">
      <HeaderMeta header={headerWithCanon}/>

      <BlogPostBox {...featuredPostNew} featured="true"/>

      <SectionGroup>
        <HeadingBox level="h1" tagLevel="h2" evenMargin={true}>
          Latest Articles
        </HeadingBox>

        <BlogPostList posts={posts}/>
      </SectionGroup>
    </Layout>
  );
};

const BlogIndexPage = (props) => {
  const { data } = props;
  const { index, main, all } = data;
  const content = main.frontmatter;
  const postList = all.edges;
  const octopus = index.frontmatter.octopus;

  return (
    <BlogHomeTemplate
      content={content}
      postList={postList}
      octopus={octopus}
    />
  )
}

export default BlogIndexPage;

export const pageQuery = graphql`
  query BlogMainPage($id: String) {
    index: markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
      ...OctopusFragment
    }
    main: markdownRemark(id: { eq: $id }) {
      ...HeaderMeta
      frontmatter {
        featuredPost {
          ...BlogPostFragment
        }
      }
    }

    all: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          ...BlogPostFragment
        }
      }
    }
  }
`;
